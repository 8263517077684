<nz-card style="width: 100%; min-height: 85vh; min-width: 1230px" nzTitle="身份管理">
  <nz-tabset
    style="position: relative; top: -15px"
    [(nzSelectedIndex)]="index"
    [nzTabBarExtraContent]="addTemp"
    (nzSelectedIndexChange)="onTagChange($event)"
  >
    <nz-tab nzTitle="身份"></nz-tab>
    <nz-tab nzTitle="规则校验"></nz-tab>
  </nz-tabset>
  <ng-template #addTemp>
    <button *ngIf="index === 0" nz-button nzType="primary" (click)="batchAdd()">批量添加情报板</button>
    <button nz-button nzType="primary" (click)="onAdd()">新建</button>
  </ng-template>
  <div class="list-search-part" nz-form [formGroup]="filterForm" *ngIf="index === 0">
    <div class="form-row">
      <div class="form-col-4">
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24">编码</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <input nz-input formControlName="serial" placeholder="请输入" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="form-col-4">
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24">名称</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <input nz-input formControlName="name" placeholder="请输入" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="form-col-4">
        <nz-form-item>
          <nz-form-label [nzSm]="4" [nzXs]="24">所属机房</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <nz-select style="width: 100%" nzShowSearch nzAllowClear formControlName="roomId" nzPlaceHolder="请选择">
              <nz-option *ngFor="let data of roomData" [nzLabel]="data.roomName" [nzValue]="data.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="form-col-4">
        <nz-form-item>
          <nz-form-label [nzSm]="4" [nzXs]="24">设备类型</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <nz-select style="width: 100%" nzShowSearch nzAllowClear formControlName="deviceType" nzPlaceHolder="请选择">
              <nz-option *ngFor="let data of typeOptions" [nzLabel]="data.text" [nzValue]="data.value"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div class="form-row">
      <div class="form-col-4">
        <nz-form-item>
          <nz-form-label [nzSm]="4" [nzXs]="24">工作模式</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <nz-select style="width: 100%" nzShowSearch nzAllowClear formControlName="workMode" nzPlaceHolder="请选择">
              <nz-option [nzLabel]="'网关'" [nzValue]="'1'"></nz-option>
              <nz-option [nzLabel]="'透传'" [nzValue]="'2'"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="form-col-4" style="text-align: right">
        <button nz-button nzType="primary" (click)="onSearch()">筛选</button>
        <button nz-button nzType="default" (click)="onReset()">重置</button>
      </div>
    </div>
  </div>
  <div nz-row [nzGutter]="24" *ngIf="index != 0">
    <div nz-col [nzSpan]="12" style="margin-bottom: 10px">
      <nz-input-group [nzSuffix]="suffixIconTpl" [nzAddOnAfter]="suffixIconButton" style="width: 100%; margin-bottom: 10px">
        <input type="text" nz-input [(ngModel)]="searchValue" placeholder="请输入名称搜索" />
      </nz-input-group>
      <ng-template #suffixIconTpl>
        <span *ngIf="searchValue" nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" (click)="onReset()"></span>
      </ng-template>
      <ng-template #suffixIconButton>
        <span nz-icon nzType="search" (click)="onSearch()"></span>
      </ng-template>
    </div>
  </div>

  <div *ngIf="index === 0">
    <nz-table
      nzSize="middle"
      [nzFrontPagination]="false"
      [nzData]="listData"
      [nzLoading]="tableLoading"
      [nzScroll]="{ x: '2200px', y: '1000px' }"
    >
      <thead>
        <tr>
          <th>名称</th>
          <th>编码</th>
          <th>身份标签</th>
          <th>所属机房</th>
          <th>IP地址</th>
          <th>MAC地址</th>
          <th>设备类型</th>
          <th>工作模式</th>
          <th>设备状态</th>
          <!--          <th>类型</th>-->
          <th>创建时间</th>
          <th nzWidth="250px" nzRight>操作</th>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr *ngFor="let data of listData; let i = index">
            <!--<td>{{ (pageIndex - 1) * pageSize + i + 1 }}</td>-->
            <td>
              <div class="flex-center">
                <nz-badge [nzStatus]="data.hasApiSession ? 'success' : 'default'"></nz-badge>
                <nz-badge [nzStatus]="data.hasEdgeRouterConnection ? 'success' : 'default'"></nz-badge>
                <!--nz-tooltip [nzTooltipTitle]="data.name"-->
                <span>{{ data?.name }}</span>
              </div>
            </td>
            <td>{{ data?.serial }}</td>
            <td>
              <nz-tag style="margin: 0 5px 5px 0" nzColor="#55acee" *ngFor="let role of data.roles"> {{ role }}</nz-tag>
            </td>
            <td>{{ data?.roomName }}</td>
            <td>{{ data?.deviceIp }}</td>
            <td>{{ data?.deviceMac }}</td>
            <td>{{ data?.deviceTypeName }}</td>
            <td>{{ data?.workMode == '1' ? '网关' : data?.workMode == '2' ? '透传' : '' }}</td>
            <td>{{ data?.statusName }}</td>
            <!--            <td>{{ data?.type ? identityTypeEnum[data?.type] : '' }}</td>-->
            <td>{{ data.createdAt | date : 'yyyy-MM-dd HH:mm' }}</td>
            <td nzWidth="250px" nzRight>
              <a (click)="onEdit(data)">编辑</a>
              <nz-divider *ngIf="data.used" nzType="vertical"></nz-divider>
              <a *ngIf="data.used" (click)="onResetAuth(data)">重新生成授权</a>
              <nz-divider *ngIf="!data.used" nzType="vertical"></nz-divider>
              <a *ngIf="!data.used" (click)="onDownloadAuth(data)">下载授权</a>
              <nz-divider nzType="vertical"></nz-divider>
              <a nz-popconfirm nzPopconfirmTitle="确定要删除该数据吗?" (nzOnConfirm)="onDelete(data)">删除</a>
              <nz-divider nzType="vertical"></nz-divider>
              <a *ngIf="data.deviceType == 7" (click)="getFlow(data)">流量</a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>

    <div class="table-pagination-box">
      <nz-pagination
        [nzPageIndex]="pageIndex"
        [nzTotal]="total"
        nzShowSizeChanger
        [nzPageSize]="pageSize"
        (nzPageIndexChange)="pageChange($event)"
        (nzPageSizeChange)="pageSizeChange($event)"
        [nzShowTotal]="totalTemplate"
      >
        <ng-template #totalTemplate let-total>共{{ total }}条</ng-template>
      </nz-pagination>
    </div>
  </div>

  <app-posture-list *ngIf="index === 1" [searchValue]="searchCheckValue" [isRefresh]="isRefreshPosture"></app-posture-list>
</nz-card>

<!--新增编辑身份-->
<app-identity-edit
  *ngIf="isVisible"
  [isVisible]="isVisible"
  [id]="id"
  [info]="info"
  (isVisibleChange)="onVisibleChange($event)"
  (isRefresh)="refreshList()"
>
</app-identity-edit>

<!--新增姿势检查-->
<app-posture-edit
  *ngIf="isPostureVisible"
  [isVisible]="isPostureVisible"
  (isVisibleChange)="onPostureVisibleChange($event)"
  (isRefresh)="onRefreshPosture($event)"
>
</app-posture-edit>

<!--查看流量明细-->
<app-traffic-detail
  *ngIf="isFlowVisible"
  [isVisible]="isFlowVisible"
  [id]="id"
  (isVisibleChange)="onFlowVisibleChange($event)"
  (isRefresh)="onRefreshFlow($event)"
>
</app-traffic-detail>
<!--批量新增情报板-->
<app-device-batchAdd
  *ngIf="isBatchVisible"
  [isVisible]="isBatchVisible"
  (isVisibleChange)="onBatchVisibleChange($event)"
  (isRefresh)="refreshList()"
>
</app-device-batchAdd>
